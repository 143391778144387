@font-face {
	font-family: 'opensans-regular';
	src: url('//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot');
	src: url('//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
	url('//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff2') format('woff2'),
	url('//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff') format('woff'),
	url('//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'opensans-bold';
	src: url('//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot');
	src: url('//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
	url('//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff2') format('woff2'),
	url('//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff') format('woff'),
	url('//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html {
	background: url("/images/celebwiki/bg.png");
}

body {
	background: url("/images/celebwiki/bg-stars.webp") top center no-repeat;
	font-family: Arial, Helvetica, sans-serif;
}

img {
	border: 0;
}
.cb { clear: both; }

.fixed {
position: fixed;
top: 0;
}

#site {
	width: 998px;
	margin: 0 auto;
	background: url("/images/celebwiki/bg-stars.webp") top center no-repeat;
}

#siteContent {
	background: url("/images/celebwiki/bg.webp");
}
#header {
	position: relative;
	text-align: center;
	padding: 40px 0;
	height: 65px;
}
#header h1 {
	position: absolute;
	text-indent: -9999px;
}
.back-to a {
	position: absolute;
	right: 6px;
	top: 50%;
	transform: translateY(-50%);
	padding: 16px 40px 16px 16px;
	font-size: 14px;
	color: #fff;
	background: #ff0000 url("/images/celebwiki/dist/svg/full-arrow-right.svg") right 16px center no-repeat;
	border-radius: 26px;
	text-decoration: none;
	z-index: 2;
}
.back-to a span {
	display: flex;
	align-self: center;
	margin-left: 10px;
}
#menu ul,
#menu li {
	margin: 0;
	padding: 0;
	list-style:none;
}
#menu ul.sections li {
	width: 119px;
	height: 26px;
	float: left;
	margin: 0 2px 0 0;
}

#menu ul.sections li a {
	display: block;
	width: 100%;
	height: 25px;
	text-decoration: none;
	font-size: 17px;
	color: #000;
	text-align: center;
	line-height: 25px;
	background: #dcdcdc url("/images/celebwiki/menu-shadow.png") center bottom no-repeat;
	border-top: 1px solid #b7b7b7;
}

#menu ul.sections li a:hover,
#menu ul.sections li.active a {
	background-color: #e80000;
	border-top: 1px solid #c10000;
	color: #fff;
}

#menu ul.sections li.active a {
	height: 28px;
	top: -3px;
	position: relative;
}

#menu ul.sections {
	margin: 0 7px 0 8px;
}
#lettersBox {
	width: 998px;
	clear: both;
	height: 56px;
	background: transparent url("/images/celebwiki/letters-bg.png") no-repeat;
	margin-bottom: 15px;
}

#lettersBox ul.letters {
	display: block;
	height: 25px;
	margin: 5px 15px;
	float: left;
}

#lettersBox ul.letters li {
	display: block;
	height: 25px;
	float: left;
	margin: 0;
}

#lettersBox ul.letters li a,
#lettersBox ul.letters li span.empty {
	display: block;
	/*width: 17px;*/
	margin: 0px 1px;
	padding: 0px 3px;
	height: 25px;
	text-align: center;
	line-height: 25px;
	color: #fff;
	text-decoration: none;
	text-transform: capitalize;
	text-shadow: -1px 0px 2px #595959;
	float: left;
}

#lettersBox ul.letters li a:hover,
#lettersBox ul.letters li a.currentLetter {
	/*background: #aa0000 url("/images/celebwiki/letters-bg.png") -27px -65px no-repeat;*/
	background: #A00;
	border-radius: 4px;
}
#lettersBox ul.letters li.letter-ch a {
	/*width: 25px;*/
}
#lettersBox ul.letters li.letter-ch a:hover,
#lettersBox ul.letters li.letter-ch a.currentLetter {
	background: #aa0000 url("/images/celebwiki/letters-bg.png") 0 -65px no-repeat;
}
#lettersBox ul.letters li span.empty {
	color: #e75a5a;
}

#lettersBox ul.letters li span.delimiter {
	border-left: 1px solid #f16666;
    display: block;
    float: left;
    height: 10px;
    margin: 7px 2px;
    width: 1px;
}
#lettersBox ul.letters li.vsichni a {
    width: 30px;
	text-transform: lowercase;

}

.birthdayBox a.more {
	display: block;
	width: 41px;
	height: 11px;
	background: transparent url("/images/celebwiki/more-hover.gif") 0 0 no-repeat;
	text-indent: -999px;
	overflow: hidden;
}
.birthdayBox  a.more:hover {
	background: transparent url("/images/celebwiki/more-hover.gif") 0 -11px no-repeat;
}

#contentLeft {
	width: 676px;
	float: left;
}

#contentRight {
	width: 300px;
	float: right;
	margin: 0 8px 0 0;
	position: relative;
}

.promoArea {
	width: 666px;
	/*background: transparent url("/images/celebwiki/bg-promo.png") 0 0 no-repeat;*/
	float: left;
	background-color: #fff;
	margin: 0 0 20px 8px;
	border: 1px solid #fff;
}

.promoArea.bottom {
	width: 980px;
}

.promoArea.bottom .jcarousel-container {
	display: block;
	height: 151px;
	position: relative;
}

.promoArea.bottom #celebrityCarousel {
	height: 119px;
	list-style: none outside none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
}

.promoArea.bottom .jcarousel-clip {
	height: 119px;
	left: 50px;
	overflow: hidden;
	position: absolute;
	top: 20px;
	width: 875px;
}

.promoArea.bottom .celebrityBox .name {
	width: 111px;
}

.promoArea li.celebrityBox {
	width: 119px;
	height: 119px;
	float: left;
	margin: 0px;
	padding: 0px 3px;
	overflow: hidden;
	background-color: #FFF;
	position: relative;
}

.promoArea.bottom .celebrityBox img.profilePhoto {
	width: 119px;
}

.promoArea.bottom a.jcarousel-prev {
	background: url("/images/celebwiki/arrows-sprites.png") no-repeat scroll 0 0 transparent;
	height: 66px;
	left: 5px;
	position: absolute;
	top: 46px;
	width: 15px;
	z-index: 10;
	cursor: pointer;
}

.promoArea.bottom a.jcarousel-next {
	background: url("/images/celebwiki/arrows-sprites.png") no-repeat scroll -16px 0 transparent;
	height: 66px;
	right: 5px;
	position: absolute;
	top: 46px;
	width: 15px;
	z-index: 10;
	cursor: pointer;
}

.drop-shadow {
	position:relative;
	-webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
	   -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
			box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.drop-shadow:before,
.drop-shadow:after {
	content:"";
	position:absolute;
	z-index:-2;
}

.drop-shadow p {
	font-size:16px;
	font-weight:bold;
}

/* Lifted corners */
.lifted:before,
.lifted:after {
	bottom:15px;
	left:10px;
	width:50%;
	height:20%;
	max-width:300px;
	max-height:100px;
	-webkit-box-shadow:0 15px 10px rgba(0, 0, 0, 0.7);
	   -moz-box-shadow:0 15px 10px rgba(0, 0, 0, 0.7);
			box-shadow:0 15px 10px rgba(0, 0, 0, 0.7);
	-webkit-transform:rotate(-3deg);
	   -moz-transform:rotate(-3deg);
		-ms-transform:rotate(-3deg);
		 -o-transform:rotate(-3deg);
			transform:rotate(-3deg);
}

.lifted:after {
	right:10px;
	left:auto;
	-webkit-transform:rotate(3deg);
	   -moz-transform:rotate(3deg);
		-ms-transform:rotate(3deg);
		 -o-transform:rotate(3deg);
			transform:rotate(3deg);
}

.promoArea .innerContent {
	margin: 5px;
}

.promoArea h2 {
	font-size: 22px;
	font-weight: normal;
	margin: 6px 6px;
	color: #505050;
	margin: 11px 6px 6px;
}

.promoArea .celebrityBox {
	width: 119px;
	height: 119px;
	float: left;
	margin: 6px;
	overflow: hidden;
	background-color: #aaa;
	position: relative;
}
.promoArea .celebrityBox.bigCelebrityBox {
	width: 206px;
	height: 206px;
}

.promoArea .celebrityBox img.profilePhoto {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
	width: 100%;
	height: 100%;
}
.promoArea .celebrityBox .name {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 4px;
	color: #fff;
	z-index: 2;
	background: transparent url("/images/celebwiki/opacity.png");
	width: 119px;
	font-size: 14px;
}
.promoArea .celebrityBox.bigCelebrityBox .name {
	width: 206px;
	padding: 8px;
}

.promoArea .celebrityBox .linkLayer {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 4px;
	color: #fff;
	z-index: 20;
	width: 111px;
	height: 111px;
	font-size: 14px;
}
.promoArea .celebrityBox.bigCelebrityBox .linkLayer {
	width: 206px;
	height: 206px;
	padding: 8px;
}
.promoArea .celebrityBox .info {
	position: absolute;
	bottom: 0;
	left: 0;
	color: #fff;
	z-index: 2;
	background: transparent url("/images/celebwiki/opacity.png");
	width: 119px;
	height: 119px;
	font-size: 14px;
	display: none;
}
.promoArea .celebrityBox .info a {
	color: #fff;
	text-decoration: none;
}
.promoArea .celebrityBox.bigCelebrityBox .info {
	width: 206px;
	height: 206px;
}

.promoArea .celebrityBox .innerInfo {
	position: absolute;
	bottom: 25px;
	left: 5px;
	width: 90%;
	text-decoration: none;
	font-size: 12px;
}

.promoArea .celebrityBox .innerInfo a .underline {
	text-decoration: underline;
}

.promoArea .celebrityBox.bigCelebrityBox .innerInfo {
	bottom: 50px;
	left: 10px;
	font-size: 15px;
}
.promoArea .celebrityBox .moreInfo {
	position: absolute;
	bottom: 5px;
	left: 5px;
}
.promoArea .celebrityBox.bigCelebrityBox .moreInfo {
	bottom: 11px;
	left: 11px;
}

.birthdayBox {
	width: 298px;
	min-height: 300px;
	float: left;
	background: transparent url("/images/celebwiki/bg-birthday.png?v=2") no-repeat;
	margin-bottom: 10px;
}
.birthdayBox h2 {
	font-size: 20px;
	font-weight: normal;
	margin: 24px 30px 10px;
	color: #505050;
}

.birthdayBox .celebrityBox {
	width: 226px;
	height: 115px;
	border-bottom: 1px solid #aaa;
	margin: 15px 30px;
	color: #000000;
}
.birthdayBox .celebrityBox.last {
	border-bottom: 0px;
	margin-bottom: 0px;
}

.birthdayBox .birthdayBottom {
	background: transparent url("/images/celebwiki/bg-birthday-bottom2.png");
	height: 20px;
}

.birthdayBox  .profilePhoto {
	display: block;
	width: 100px;
	height: 100px;
	float: left;
}
.birthdayBox  .profilePhoto img {
	width: 100px;
	height: 100px;
}
.birthdayBox  .info {
	width: 120px;
	float: right;
}

.birthdayBox  .info table {
	width: 110px;
	height: 89px;
	border-collapse: collapse;
}
.birthdayBox  .info table td {
	font-size: 13px;
}

.articlesBoxHp {
	background: transparent url("/images/celebwiki/hp-header-line.png") repeat-x;
	float: left;
	width: 370px;
	margin-left: 8px;
}
.articlesBoxHp h2 {
	font-size: 20px;
	font-weight: normal;
	margin: 24px 10px 10px;
	color: #505050;
}

.articlesBoxHp .articleBox {
	margin: 8px 10px;
	float: left;
	width: 365px;
	height: 115px;
	background: transparent url("/images/celebwiki/articlebox-border.png") left bottom repeat-x;
}

.articlesBoxHp .last .articleBox.last {
	background: none;
}
.articlesBoxHp .articleBox a.articlePhoto {
	display: block;
	width: 150px;
	height: 100px;
	float: left;
	background-color: #fff;
}
.articlesBoxHp .articleBox a.articlePhoto img {
	width: 150px;
	height: 100px;
	font-size: 13px;
	color: #000;
	text-decoration:none;

}
.articlesBoxHp .articleBox .info {
	width: 205px;
	float: right;
}
.articlesBoxHp .articleBox a {
	color: #000000;
	display: inline-block;
	font-size: 14px;
	text-decoration: none;
	font-weight: bold;
	margin-bottom: 4px;
}

.articlesBoxHp .articleBox .leadsection {
	font-size: 12px;
}

.articlesBoxHp .articleBox a:hover {
	color: #e80000;
}
.articlesBoxHp .articleBox .readArticle {
	color: #e80000;
	font-size: 11px;
	font-weight: bold;
	text-decoration: none;
	padding: 0 10px 0 0;
	background: transparent url("/images/celebwiki/read-article.gif") right center no-repeat;
	white-space: nowrap;
}

.articlesBoxHp .articleBox .readArticle:hover {
	text-decoration: underline;
}

.articlesBoxHp .articleBox .logo {
	display: block;
	width: 46px;
	height: 15px;
	margin-bottom: 5px;
}
.articlesBoxHp .bleskcz .logo {
	background: transparent url("/images/celebwiki/magazines-logos.png") 0 0 no-repeat;
}
.articlesBoxHp .ahaonlinecz .logo {
	background: transparent url("/images/celebwiki/magazines-logos.png") 0 -26px no-repeat;
}
.articlesBoxHp .reflexcz .logo {
	background: transparent url("/images/celebwiki/magazines-logos.png") 0 -30px no-repeat;
}
.articlesBoxHp .isportcz .logo {
	background: transparent url("/images/celebwiki/magazines-logos.png") 0 -51px no-repeat;
}

/****** výpis kategorie *******/
.category {
	margin: 10px;
}
.categoryTitle {
	padding: 0 0 5px;
	background: transparent url("/images/celebwiki/title-line.gif") left bottom repeat-x;
}
.categoryTitle h1 {
	margin: 0;
	padding: 0;
	font-size: 28px;
}

.category .bigLetter {
	width: 220px;
	clear: both;
	border-bottom: 1px solid #c4c4c4;
	font-size: 22px;
	text-transform: uppercase;
	margin: 30px 0 5px 0;
}

.category .person  {
	width: 218px;
	height: 102px;
	float: left;
	margin: 7px 0px 7px 0;
}
.category .person a {
	width: 210px;
	height:40px;
	display: table;
	color: #000;
	text-decoration: none;
	font-size: 12px;
}
.category .person a:hover {
	background-color: #c4c4c4;
}

.category .person a img{
	width: 80px;
	height: 80px;
	margin: 1px 6px 1px 1px;
	float: left;
}

.category .person a span {
	width: 123px;
	display: block;
	float: left;
	padding-top: 5px;
}


/****** profil celebrity ******/
.personDetail {
	margin: 0px 0px 10px 8px;
	float: right;
	width: 480px;
	color: #131313;
	font-size: 16px;
	line-height: 23px;
}

.personDetail p {
	margin: 0px 0px 10px;
}

.personDetail .description {
	font-weight: bold;
	margin-bottom: 20px;
	width: 480px;

}

.personDetail p {
	font-size: 16px;
	text-align: left !important;
}

.personDetail a,
.personDetail a.personHL {
	color: #E80000;
}


.personDetail .userForm {
	width: 448px;
	/*float: left;*/
	background-color: white;
	margin: 0 0 20px 8px;
	border: 1px solid white;
	padding: 15px;
	margin: 10px 0px 30px;
}

.personDetail .userForm .formTitle {
	font-size: 20px;
	font-weight: normal;
	margin-bottom: 15px;
	color: #505050;
	text-align: left;
	line-height: 23px;
}

.personDetail .userForm input, .personDetail .userForm textarea {
	background: #f5f5f5;
	border: 1px solid #dbdbdb;
	width: 200px;
}

.personDetail .userForm textarea {
	width: 440px;
	height: 130px;
	resize: none;
}

.personDetail .userForm .redSubmit {
	background: #e80000;
	border-top: 1px solid #720000;
	color: white;
	padding: 10px;
	cursor: pointer;
	font-size: 15px;
	float: right;
	margin: 10px 0px 0px;
}

.personDetail .userForm label {
	color: #2b2b2b;
	font-size: 15px;
	display: block;
	float: left;
	margin-top: 5px;
}

.personDetail .userForm label.error {
	color: red;
}

.personDetail .userForm .formName {
	float: left;
	width: 224px;
}

.personDetail .userForm .formEmail {
	float: right;
	width: 204px;
	padding-left: 20px;
}

.profileArea {
	width: 666px;
	height: auto;
	background-color: white;
	margin: 0 0 30px 8px;
	border: 1px solid white;
}

.leftColumn {
    float: left;
    width: 374px

}

.profileArea .innerContent {
	width: 636px;
	float: left;
	margin: 10px 15px;
}
.profileArea .info {
	float: right;
	width: 248px;
}

.profileArea .personInfo {
	font-size: 15px;
	border-bottom: 1px solid #D8D8D8;
	margin: 10px 0px;
	padding: 10px 0px;
	border-top: 1px solid #D8D8D8;
}

.profileArea .info .pInfo strong {
	margin-right: 5px;
}

.profileArea .info h1 {
	margin-bottom: 5px;
	color: #161616;
	padding: 0px 0 5px;
	font-size: 25px;
	font-weight: normal;
	margin-top: 0px;
}

.profileArea .info .birth {
    /*float: left;*/
    padding-right: 10px;
    font-size: 13px;
}

.profileArea .info .death {
    /*float: left;*/
    font-size: 13px;
}

.profileArea .gallery {
    float: left;
    width: 374px;
}

.profileArea .gallery .galleryInfo {
	margin: 0px;
	font-size: 13px;
	color: #000;
	float: left;
	border-bottom: 1px solid #C7C7C7;
	background-color: #F9F9F9;
	margin: 0px 0px;
	padding: 3px 5px;
	width: 364px;
}
.profileArea .gallery .galleryInfo a.linkToGallery {
	color: #f00;
	display: block;
	float: right;
	text-decoration: none;
}

.profileArea .gallery .galleryInfo a.linkToGallery:hover {
	text-decoration: underline;
}

.personInfo .pInfo {
	margin-bottom: 2px;
}

.personRelated {
	width: 140px;
	float: left;
	padding: 10px;
	border: 1px solid white;
	background: #FFF;
	margin: 10px 0px 10px 10px;
}

#contentRight .personRelated {
	width: 278px;
	float: left;
	padding: 10px 10px;
	border: 1px solid white;
	background: #FFF;
	margin: 0px 0px 10px 0px;
}

.personRelated.noTopMargin {
	margin-top: 0px;
}

.personRelated h2 {
	font-size: 20px;
	font-weight: normal;
	margin: 0px 10px 10px;
	color: #505050;
	text-align: left;
}

.personRelated .catInfo {
	text-transform: lowercase;
}

.personRelated h2 .fletter {
	text-transform: uppercase;
}

#contentLeft #verticalCarousel {
	margin: 40px 0px 40px 0px;
	padding: 0px;
	list-style: none;
	list-style: none outside none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
	padding: 0px 0px;
}

#contentLeft #verticalCarousel.withHeight{
	height: 645px;
}

.personRelated a.jcarousel-prev,
.personRelated a.jcarousel-next {
	background: url("/images/celebwiki/arrows-sprites.png") no-repeat scroll -45px 0 transparent;
	height: 18px;
	right: 45px;
	position: absolute;
	width: 66px;
	z-index: 10;
	cursor: pointer;
	top: 70px;
}

.personRelated a.jcarousel-next {
	top: 760px;
	background: url("/images/celebwiki/arrows-sprites.png") no-repeat scroll -45px -17px transparent;
}

.personRelated #verticalCarousel .relatedProfile {
	float: none;
}


#verticalCarousel li {
	width: 119px;
	height: 119px;
	padding: 0px 10px 36px;
	margin-bottom: 10px;
	position: relative;
}

.personRelated .jcarousel-clip-vertical {
	height: 660px;
	left: 0px;
	overflow: hidden;
	position: relative;
	top: 0px;
	width: 140px;
	margin: 40px 0px;
}

.personRelated .relatedProfile {
	width: 119px;
	height: 119px;
	padding: 0px 10px;
	margin-bottom: 10px;
	position: relative;
	float: left;
}

.personRelated .relatedProfile img {
	width: 100%;
	height: 100%;
}

.personRelated .relatedProfile .info {
	position: absolute;
	bottom: 0;
	left: 10px;
	color: white;
	z-index: 2;
	background: transparent url("/images/celebwiki/opacity.png");
	width: 119px;
	height: 119px;
	font-size: 14px;
	display: none;
}

#verticalCarousel .relatedProfile .info {
	height: 156px;
}

.personRelated .relatedProfile .info .innerInfo {
	position: absolute;
	bottom: 25px;
	left: 5px;
	width: 90%;
	text-decoration: underline;
	font-size: 12px;
}

.personRelated .relatedProfile .info .innerInfo a {
	text-decoration: none;
	color: white;
}

.personRelated .relatedProfile .info .moreInfo {
	position: absolute;
	bottom: 5px;
	left: 5px;
	width: 41px;
	height: 11px;
}

.personRelated .relatedProfile .name {
	position: absolute;
	bottom: 0;
	left: 10px;
	padding: 3px 5px;
	color: white;
	z-index: 2;
	background: transparent url("/images/celebwiki/opacity.png");
	width: 109px;
	font-size: 12px;
}

#verticalCarousel .personRelated .relatedProfile .name {
	position: static;
}

.personRelated .relatedProfile .linkLayer {
	position: absolute;
	bottom: 0;
	left: 10px;
	padding: 0px;
	color: white;
	z-index: 20;
	width: 119px;
	height: 156px;
	font-size: 14px;
}

.personRelatedArts {

	border-bottom: 1px solid #CECECE;
	background: white;

	padding: 10px;
	margin: 20px 0px;
}

.personRelatedArts .magazine {
	background: transparent url(/images/celebwiki/logo-sprites-middle.png) no-repeat;
	width: 71px;
	height: 24px;
	margin: 10px 0px;
}

.personRelatedArts .magazine a {
	display: block;
	width: 71px;
	height: 24px;
}

.personRelatedArts .magazine.Blesk {
	background-position: 0px 0px;
}

.personRelatedArts .magazine.Aha {
	background-position: 0px -24px;
}

.personRelatedArts .magazine.iSport {
	background-position: 0px -48px;
}

.twoPixelsBorder {
	width: 100%;
	height: 0px;
	border-top: 1px solid #C6C6C6;
	border-bottom: 2px solid white;
	margin-top: 20px;
}

.personRelatedArts .relatedArtsTitle {
	color: #505050;
	font-size: 18px;
	margin-top: 0px;
	margin-bottom: 20px;
	font-weight: normal;
}

.personRelatedArts .articles .item {
	margin-bottom: 10px;
	border-bottom: 1px solid #DDD;
	padding-bottom: 10px;
}

.personRelatedArts .articles .item.last {
    border-bottom: 0px;
}

.personRelatedArts .articles .item a {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  display: block;
}

.personRelatedArts .articles .item {
    font-size: 14px;
    line-height: 21px;
}

.personRelatedArts .articles .item img {
    float: left;
    margin-right: 10px;
    width: 150px;
}

.personRelatedArts .articles .item a:hover {
	color: #FF0000;
}

.personRelatedArts .articles .item a.readArticle {
	color: #e80000;
	font-size: 11px;
	font-weight: bold;
	text-decoration: none;
	padding: 0 10px 0 7px;
	background: transparent url("/images/celebwiki/read-article.gif") 63px center no-repeat;
	display: inline-block;
	padding: 5px 20px 5px 0px;
}

.personRelatedArts .articles .item a.readArticle:hover {
	text-decoration: underline;
}

.celebwikiSearch {
	float: right;
	margin: 0px 12px;
}

.celebwikiSearch fieldset {
	margin: 0px;
	padding: 0px;
	border: none;
}

.searchResult .categoryTitle {
	background: none;
}

.celebwikiSearch .searchField {
	border: 1px solid #C60000;
	padding: 1px 5px;
	line-height: 23px;
	color: #757575;
	box-shadow: inset 2px 0px 10px 0px #CCC;
	height: 23px;
	margin-right: 5px;
	margin-top: 4px;
	float: left;
}

.celebwikiSearch .searchZoom {
  text-indent: -9999px;
  width: 28px;
  height: 27px;
  background: transparent url(/images/celebwiki/search-zoom.png) no-repeat 0px 0px;
  border: none;
  cursor: pointer;
  margin-top: 4px;
}

.celebwikiSearch .searchZoom:hover {
	background: transparent url(/images/celebwiki/search-zoom.png) no-repeat 0px -27px;
}

.socialButtons {
	margin: 0px 0px 0px;
	width: 248px;
	float: right;
	height: 40px;
	border-bottom: 1px solid #D8D8D8;
}

.socialButtons a.facebook {
	width: 33px;
	height: 32px;
	display: block;
	float: right;
	background: transparent url(/images/celebwiki/social.png) no-repeat 0px 0px;
	margin-right: 10px;
}

.socialButtons a.twitter {
	width: 33px;
	height: 32px;
	display: block;
	float: right;
	background: transparent url(/images/celebwiki/social.png) no-repeat -44px 0px;
	margin-right: 10px;
}

.socialButtons a.gplus {
	width: 33px;
	height: 32px;
	display: block;
	float: right;
	background: transparent url(/images/celebwiki/social.png) no-repeat -87px 0px;
	overflow: hidden;
	margin-right: 0px;
}

.fb_edge_widget_with_comment {
	margin: 0px 10px 0px 0px;
	float: left;
	margin-top: 0px;
	position: relative;
	top: 0px;
}

.fb_share_button {
	margin: 0px 10px 0px 0px;
	height: 18px;
	display: inline-block;
	margin-top: 2px;
}

.error404 {
	font-size: 22px;
	font-weight: normal;
	margin: 6px 6px;
	color: #505050;
}

img.missingPhoto {
	margin-left: 84px;
	margin-bottom: 10px;
}

.topRelatedArticles {
    padding-top: 0px;
}

.topRelatedArticles .title {
    color: #E80000;
    line-height: 20px;
    font-size: 15px;
    text-decoration: none;
    margin: 10px 0px;
}

.topRelatedArticles .item {
	margin-bottom: 20px;
}

.topRelatedArticles .item a {
  color: #000000;
  font-size: 15px;
  text-decoration: none;
  font-weight: bold;
}

.topRelatedArticles .item a:hover {
	color: #FF0000;
}

.topRelatedArticles .item a.readArticle {
	color: #e80000;
	font-size: 11px;
	font-weight: bold;
	text-decoration: none;
	padding: 0 10px 0 7px;
	background: transparent url("/images/celebwiki/read-article.gif") 68px center no-repeat;
	display: inline-block;
	padding: 5px 10px 5px 5px;
}

.topRelatedArticles .item a.readArticle:hover {
	text-decoration: underline;
}

/* fotogalerie u celebrity */

.gallery .videoGallery {
	float: left;
	width: 374px;
	position: relative;
	left: 0px;
}

.gallery .photoGallery {
	float: left;
	width: 374px;
}

.gallery .galleryTitle, .gallery .galleryVideoTitle {
	color: #e80000;
	line-height: 20px;
	font-size: 15px;
	padding: 10px 22px;
}

.gallery .galleryCount {
    color: #999999;
    font-size: 13px;
}

.gallery .galleryTitle a, .gallery .galleryVideoTitle a {
	color: #e80000;
	line-height: 20px;
	font-size: 15px;
	text-decoration: none;
}

.gallery .galleryTitle a:hover, .gallery .galleryVideoTitle a:hover {
	text-decoration: underline;
}

.gallery .photoGallery .jcarousel-container {
	display: block;
	height: 80px;
	position: relative;
}


.gallery .videoGallery .jcarousel-container {
	display: block;
	height: 104px;
	position: relative;
}

.gallery #personGallery, .gallery #personVideoGallery {
	height: 122px;
	list-style: none outside none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
	padding: 0px 0px;
}


.gallery .videoGallery .jcarousel-clip {
	width: 336px;
}

.gallery .jcarousel-clip {
	height: 104px;
	left: 0px;
	overflow: hidden;
	position: absolute;
	top: 0px;
	width: 336px;
	left: 22px;
}

.gallery #personGallery li.personGalleryPhoto {
	width: 106px;
	float: left;
	margin: 0px;
	padding: 0px 6px 0px 0px;
	overflow: hidden;
	background-color: #FFF;
	position: relative;
}

.gallery #personGallery li.personGalleryPhoto.noRightPadding {
	padding: 0px;
}


.gallery #personVideoGallery li.personGalleryVideo {
	width: 156px;
	float: left;
	margin: 0px;
	padding: 0px 12px 0px 0px;
	overflow: hidden;
	background-color: #FFF;
	position: relative;
}

.gallery #personVideoGallery {
 padding-left: 22px;
}

.gallery #personVideoGallery.jcarousel-list-horizontal {
 padding-left: 0px;
}

.gallery #personGallery li.personGalleryPhoto img {
	width: 106px;
}

.gallery #personGallery li.personGalleryPhoto img.overlayPlay {
	position: absolute;
	z-index: 2;
	width: 81px;
	left: 13px;
	top: -4px;
}

.gallery #personVideoGallery li.personGalleryVideo img {
	width: 156px;
}

.gallery #personVideoGallery li.personGalleryVideo img.overlayPlay {
	position: absolute;
	z-index: 2;
	width: 81px;
	left: 38px;
	top: 14px;
}

.gallery a.jcarousel-prev {
	background: url("/images/celebwiki/arrows-sprites.png") no-repeat scroll 0 0 transparent;
	height: 66px;
	left: 0px;
	position: absolute;
	top: 6px;
	width: 15px;
	z-index: 10;
	cursor: pointer;
}

.gallery a.jcarousel-next {
	background: url("/images/celebwiki/arrows-sprites.png") no-repeat scroll -16px 0 transparent;
	height: 66px;
	right: 0px;
	position: absolute;
	top: 6px;
	width: 15px;
	z-index: 10;
	cursor: pointer;
}

.videoGallery a.jcarousel-next, .videoGallery a.jcarousel-prev {
    top: 16px;
}

.videoGallery .jcarousel-clip {
    left: 26px
}

.personDetail .lastRelatedArticle a {
	color: #000000;
	font-size: 15px;
	text-decoration: none;
	font-weight: bold;
}

.personDetail .lastRelatedArticle a:hover {
	color: #FF0000;
}

.personDetail .lastRelatedArticle a.readArticle {
	color: #e80000;
	font-size: 11px;
	font-weight: bold;
	text-decoration: none;
	padding: 0 10px 0 7px;
	background: transparent url("/images/celebwiki/read-article.gif") 68px center no-repeat;
	display: inline-block;
	padding: 5px 10px 5px 5px;
}

.personDetail .lastRelatedArticle a.readArticle:hover {
	color: #e80000;
	text-decoration: underline;
}

.personDetail .lastArticlesTitle {
	color: #E80000;
	line-height: 20px;
	font-size: 15px;
	text-decoration: none;
	margin: 10px 0px;
}
/**************************************************
COOKIE BAR - mobile first
***************************************************/
.cookie-bar {
	position: fixed;
	display: none;
	text-align: center;
	padding: 10px 0;
	font-family: 'opensans-regular';
	color: #FFF;
	bottom: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	background-color: rgba(50, 50, 50, 0.95);
	width: 100%;
	z-index: 200000;
}
.cookie-bar a {
	color: inherit;
}

.cookie-bar__title {
	font-family: 'opensans-bold';
	font-size: 22px;
	line-height: 26px;
}
.cookie-bar__title br {
	display: inline;
}

.cookie-bar__text {
	font-size: 12px;
	line-height: 16px;
	padding: 8px 0 13px;
}
.cookie-bar__text br {
	display: none;
}

.cookie-bar__enable-btn {
	display: inline-block;
	margin: 0 10px 0 auto;
	font-size: 14px;
	line-height: 35px;
	height: 35px;
	width: 140px;
	text-transform: Uppercase;
	background-color: #FF0000;
	cursor: pointer;
	margin-bottom: 13px;
	text-decoration: none;
}

.cookie-bar__more-info {
	font-size: 11px;
	margin-bottom: 5px;
	line-height: 15px;
	display: inline-block;
	text-decoration: underline;
}
.cookie-bar__more-info:hover {
	text-decoration: none;
}

.cookie-bar__close-btn {
	display: inline-block;
	width: 14px;
	height: 14px;
	overflow: hidden;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
}
.cookie-bar__close-btn::before, .cookie-bar__close-btn::after {
	content: '';
	position: absolute;
	height: 2px;
	width: 100%;
	top: 50%;
	left: 0;
	margin-top: -1px;
	background: #FFF;
}
.cookie-bar__close-btn::before {
	transform: rotate(45deg);
}
.cookie-bar__close-btn::after {
	transform: rotate(-45deg);
}
.cookie-bar__close-btn--thick::before, .cookie-bar__close-btn--thick::after {
	height: 3px;
	margin-top: -2px;
}

/**************************************************
COOKIE BAR - desktop
***************************************************/
@media only screen and (min-width: 480px) {

	.cookie-bar {
		width: 100%;
	}

	.cookie-bar__title {
		font-size: 26px;
		line-height: 36px;
	}
	.cookie-bar__title br {
		display: none;
	}

	.cookie-bar__text {
		padding: 5px 0 10px;
	}
	.cookie-bar__text br {
		display: inline;
	}

	.cookie-bar__enable-btn {
		display: inline-block;
		margin: 0 15px 0 auto;
	}

	.cookie-bar__more-info {
		margin-bottom: 0px;
	}

}

.gam-wrapper {
 	display: block;
  clear: both;
}